@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,700;1,200;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;700&display=swap");
:root {
  --page-header-background: url("../imgs/pageHeaderBackground.jpg");
  --emphasis-multiplyer: 1;
  --box-shadow: 0px 12px 27px -5px hsla(225, 30%, 28%, 0.25), 0px 8px 16px -8px hsla(225, 30%, 28%, 0.3);
  --body-background-h: 0deg;
  --body-background-s: 0%;
  --body-background-l: 100%;
  --body-background-hsl: 0deg 0% 100%;
  --body-backgroundHSL: 0deg 0% 100%;
  --body-background-light-hsl: 0deg 0% 100%;
  --body-background-lighter-hsl: 0deg 0% 100%;
  --body-background-lightest-hsl: 0deg 0% 100%;
  --body-background-dark-hsl: 0deg 0% 88%;
  --body-background-darker-hsl: 0deg 0% 75%;
  --body-background-darkest-hsl: 0deg 0% 63%;
  --colour-app-h: 213deg;
  --colour-app-s: 20%;
  --colour-app-l: 28%;
  --colour-app-hsl: 213deg 20% 28%;
  --colour-appHSL: 213deg 20% 28%;
  --colour-app-light-hsl: 213deg 20% 46%;
  --colour-app-lighter-hsl: 213deg 20% 64%;
  --colour-app-lightest-hsl: 213deg 20% 82%;
  --colour-app-dark-hsl: 213deg 20% 21%;
  --colour-app-darker-hsl: 213deg 20% 14%;
  --colour-app-darkest-hsl: 213deg 20% 7%;
  --colour-feature-h: 205deg;
  --colour-feature-s: 96%;
  --colour-feature-l: 52%;
  --colour-feature-hsl: 205deg 96% 52%;
  --colour-featureHSL: 205deg 96% 52%;
  --colour-feature-light-hsl: 205deg 96% 64%;
  --colour-feature-lighter-hsl: 205deg 96% 76%;
  --colour-feature-lightest-hsl: 205deg 96% 88%;
  --colour-feature-dark-hsl: 205deg 96% 39%;
  --colour-feature-darker-hsl: 205deg 96% 27%;
  --colour-feature-darkest-hsl: 205deg 96% 14%;
  --colour-font-h: 213deg;
  --colour-font-s: 20%;
  --colour-font-l: 48%;
  --colour-font-hsl: 213deg 20% 48%;
  --colour-fontHSL: 213deg 20% 48%;
  --colour-font-light-hsl: 213deg 20% 61%;
  --colour-font-lighter-hsl: 213deg 20% 74%;
  --colour-font-lightest-hsl: 213deg 20% 87%;
  --colour-font-dark-hsl: 213deg 20% 36%;
  --colour-font-darker-hsl: 213deg 20% 24%;
  --colour-font-darkest-hsl: 213deg 20% 12%;
  --body-backgroundHSL: var(--body-background-h), var(--body-background-s), var(--body-background-l);
  --colour-featureHSL: var(--colour-feature-h), var(--colour-feature-s), var(--colour-feature-l);
  --colour-fontHSL: var(--colour-font-h), var(--colour-font-s), var(--colour-font-l);
  --colour-appHSL: var(--colour-app-h), var(--colour-app-s), var(--colour-app-l);
}

:root [data-theme=lightforge] {
  --colour-feature-h: 269deg;
  --colour-feature-s: 100%;
  --colour-feature-l: 29%;
  --colour-feature-hsl: 269deg 100% 29%;
  --colour-featureHSL: 269deg 100% 29%;
  --colour-feature-light-hsl: 269deg 100% 47%;
  --colour-feature-lighter-hsl: 269deg 100% 65%;
  --colour-feature-lightest-hsl: 269deg 100% 82%;
  --colour-feature-dark-hsl: 269deg 100% 22%;
  --colour-feature-darker-hsl: 269deg 100% 15%;
  --colour-feature-darkest-hsl: 269deg 100% 7%;
  --colour-font-h: 240deg;
  --colour-font-s: 12%;
  --colour-font-l: 42%;
  --colour-font-hsl: 240deg 12% 42%;
  --colour-fontHSL: 240deg 12% 42%;
  --colour-font-light-hsl: 240deg 12% 57%;
  --colour-font-lighter-hsl: 240deg 12% 71%;
  --colour-font-lightest-hsl: 240deg 12% 86%;
  --colour-font-dark-hsl: 240deg 12% 32%;
  --colour-font-darker-hsl: 240deg 12% 21%;
  --colour-font-darkest-hsl: 240deg 12% 11%;
  --colour-featureHSL: var(--colour-feature-h), var(--colour-feature-s), var(--colour-feature-l);
  --colour-fontHSL: var(--colour-font-h), var(--colour-font-s), var(--colour-font-l);
  --page-header-background: url("../imgs/theme/lightforge/pageHeaderBackground.jpg");
}

:root [data-theme=asd] {
  --page-header-background: url("../imgs/theme/asd/pageHeaderBackgroundAlt.jpg");
  --body-background-h: 0deg;
  --body-background-s: 0%;
  --body-background-l: 100%;
  --body-background-hsl: 0deg 0% 100%;
  --body-backgroundHSL: 0deg 0% 100%;
  --body-background-light-hsl: 0deg 0% 100%;
  --body-background-lighter-hsl: 0deg 0% 100%;
  --body-background-lightest-hsl: 0deg 0% 100%;
  --body-background-dark-hsl: 0deg 0% 88%;
  --body-background-darker-hsl: 0deg 0% 75%;
  --body-background-darkest-hsl: 0deg 0% 63%;
  --colour-app-h: 0deg;
  --colour-app-s: 6%;
  --colour-app-l: 33%;
  --colour-app-hsl: 0deg 6% 33%;
  --colour-appHSL: 0deg 6% 33%;
  --colour-app-light-hsl: 0deg 6% 50%;
  --colour-app-lighter-hsl: 0deg 6% 67%;
  --colour-app-lightest-hsl: 0deg 6% 83%;
  --colour-app-dark-hsl: 0deg 6% 25%;
  --colour-app-darker-hsl: 0deg 6% 17%;
  --colour-app-darkest-hsl: 0deg 6% 8%;
  --colour-feature-h: 0deg;
  --colour-feature-s: 98%;
  --colour-feature-l: 39%;
  --colour-feature-hsl: 0deg 98% 39%;
  --colour-featureHSL: 0deg 98% 39%;
  --colour-feature-light-hsl: 0deg 98% 54%;
  --colour-feature-lighter-hsl: 0deg 98% 70%;
  --colour-feature-lightest-hsl: 0deg 98% 85%;
  --colour-feature-dark-hsl: 0deg 98% 29%;
  --colour-feature-darker-hsl: 0deg 98% 20%;
  --colour-feature-darkest-hsl: 0deg 98% 10%;
  --colour-font-h: 0deg;
  --colour-font-s: 0%;
  --colour-font-l: 33%;
  --colour-font-hsl: 0deg 0% 33%;
  --colour-fontHSL: 0deg 0% 33%;
  --colour-font-light-hsl: 0deg 0% 50%;
  --colour-font-lighter-hsl: 0deg 0% 67%;
  --colour-font-lightest-hsl: 0deg 0% 83%;
  --colour-font-dark-hsl: 0deg 0% 25%;
  --colour-font-darker-hsl: 0deg 0% 17%;
  --colour-font-darkest-hsl: 0deg 0% 8%;
  --colour-featureHSL: var(--colour-feature-h), var(--colour-feature-s), var(--colour-feature-l);
  --colour-fontHSL: var(--colour-font-h), var(--colour-font-s), var(--colour-font-l);
}

.carouselWrapper {
  --carouselPadding: 30px;
  width: calc(100% + 4rem + 2 * var(--carouselPadding));
  transform: translateX(calc(-2rem - var(--carouselPadding)));
  overflow: hidden;
}

.p-carousel-item {
  padding: var(--carouselPadding);
  padding-top: 0;
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  width: 2rem;
  height: 2rem;
  color: hsl(var(--colour-fontHSL));
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

.p-carousel .p-carousel-content .p-carousel-prev:enabled:hover,
.p-carousel .p-carousel-content .p-carousel-next:enabled:hover {
  color: #495057;
  border-color: transparent;
  background: #e9ecef;
}

.p-carousel .p-carousel-content .p-carousel-prev:focus,
.p-carousel .p-carousel-content .p-carousel-next:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-carousel .p-carousel-indicators {
  gap: 8px;
  padding: 0 0 16px 16px;
  margin: auto;
  width: min(90%, 1200px);
}

.p-carousel .p-carousel-indicators .p-carousel-indicator {
  list-style: none;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button {
  background-color: hsl(var(--colour-fontHSL));
  width: 2rem;
  height: 0.5rem;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: 0;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator button:hover {
  background: #dee2e6;
}

.p-carousel .p-carousel-indicators .p-carousel-indicator.p-highlight button {
  background: hsl(var(--colour-featureHSL));
  color: #495057;
}

.carouselWrapper.indicatorTop .p-carousel-container {
  order: 1;
}
.carouselWrapper.indicatorRight .p-carousel-indicators {
  justify-content: end;
}
.carouselWrapper.indicatorLeft .p-carousel-indicators {
  justify-content: start;
}

.vert .p-carousel-items-container .p-carousel-item {
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.p-checkbox .p-checkbox-box {
  border: 1px solid hsl(var(--colour-fontHSL));
  background: #ffffff;
  width: 2.5rem;
  height: 2.5rem;
  margin-right: 16px;
  color: hsl(var(--colour-fontHSL));
  border-radius: 3px;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

:is(.p-multiselect-panel, .p-dropdown-panel) .p-checkbox .p-checkbox-box {
  width: 1.25rem;
  height: 1.25rem;
  border-width: 2px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 14px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: hsl(var(--colour-featureHSL));
  background: hsl(var(--colour-featureHSL));
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  border-color: #ffbb00;
  background: #ffbb00;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: hsl(var(--colour-fontHSL));
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  border-color: hsl(var(--colour-fontHSL));
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: hsl(var(--colour-fontHSL));
  background: hsl(var(--colour-fontHSL));
  color: #ffffff;
}

.p-checkbox:is(.p-checkbox-disabled) {
  cursor: not-allowed;
}
.p-checkbox:is(.p-checkbox-disabled) .p-checkbox-box {
  border-color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.66 + var(--colour-font-l)));
}

.p-checkbox.p-invalid > .p-checkbox-box {
  border-color: #f44336;
}

.p-column-filter-menu-button span.pi-filter {
  z-index: 2;
}

.p-column-filter-menu-button-open,
.p-column-filter-menu-button-active {
  color: hsl(var(--colour-featureHSL));
}

.p-column-filter-overlay {
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  background-color: hsla(var(--body-background-h), var(--body-background-s), var(--body-background-l), 0.8);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  background-clip: padding-box;
  overflow: hidden;
}

.p-column-filter-operator,
.p-column-filter-constraints,
.p-column-filter-add-rule,
.p-column-filter-buttonbar {
  padding: 16px;
}

.p-column-filter-operator {
  padding: 8px 16px;
  background-color: hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.1);
  border-bottom: 1px solid hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.2);
}

.p-column-filter-constraints {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.p-column-filter-constraints .p-column-filter-constraint .p-column-filter-matchmode-dropdown {
  margin-bottom: 8px;
}
.p-column-filter-constraints input + div:not(.p-component) {
  display: flex;
  justify-content: center;
}

.p-column-filter-buttonbar {
  order: -1;
  gap: 16px;
  border-bottom: 1px solid hsla(var(--colour-font-h), var(--colour-font-s), var(--colour-font-l), 0.2);
}

.p-column-filter-add-rule {
  display: flex;
  justify-content: center;
  padding-top: 0;
}
.p-column-filter-add-rule .p-button.p-column-filter-add-button {
  border: none;
  font-size: 0.75rem;
  color: hsl(var(--colour-featureHSL));
}

.p-button.p-column-filter-remove-button {
  display: block;
  margin: auto;
  border: none;
  font-size: 0.75rem;
  color: #c13744;
}

.p-column-filter-menu-button.p-column-filter-menu-button-active,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover {
  color: white;
  overflow: visible;
}
.p-column-filter-menu-button.p-column-filter-menu-button-active::before,
.p-column-filter-menu-button.p-column-filter-menu-button-active:hover::before {
  --oversize: 100%;
  content: "";
  position: absolute;
  top: calc(var(--oversize) * -0.5);
  left: calc(var(--oversize) * -0.5);
  height: calc(100% + var(--oversize));
  aspect-ratio: 1 !important;
  background-color: hsl(var(--colour-featureHSL));
  opacity: 1;
  transform: scale(1);
  border-radius: 50%;
}

.p-datatable table {
  border-collapse: collapse;
}

.p-datatable-auto-layout > .p-datatable-wrapper {
  /* overflow-x: hidden; */
  overflow-y: visible;
  z-index: 2;
}
.p-datatable-auto-layout > .p-datatable-wrapper table {
  height: 1px;
}

.p-datatable-header {
  display: none;
}

.p-datatable-thead {
  border-bottom: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.33 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  text-align: left;
}
.p-datatable-thead th {
  padding: 0px 16px;
  font-size: 0.75rem;
}
.p-datatable-thead th input,
.p-datatable-thead th .p-dropdown-label,
.p-datatable-thead th .p-multiselect-label {
  font-size: 0.75rem;
  color: hsl(var(--colour-fontHSL));
}
.p-datatable-thead tr:first-of-type th {
  text-transform: uppercase;
}
.p-datatable-thead tr:last-of-type th {
  padding: 8px 16px 16px 16px;
}
.p-datatable-thead .p-column-header-content {
  flex-wrap: wrap;
}
.p-datatable-thead .p-column-header-content > span:first-of-type {
  flex: 1;
}
.p-datatable-thead .p-sortable-column {
  vertical-align: bottom;
}
.p-datatable-thead .p-column-header-content {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr auto;
  grid-template-columns: auto auto 1fr;
  grid-template-areas: "title title title" "sortOrder sort filter";
  row-gap: 8px;
}
.p-datatable-thead .p-column-header-content .p-column-title {
  grid-area: title;
  align-self: end;
}
.p-datatable-thead .p-column-header-content .p-sortable-column-icon {
  grid-area: sort;
}
.p-datatable-thead .p-column-header-content .p-column-filter {
  grid-area: filter;
}
.p-datatable-thead .p-sortable-column.p-highlight {
  color: hsl(var(--colour-featureHSL));
}
.p-datatable-thead .p-sortable-column-badge {
  display: flex;
  align-items: center;
  height: 0.75rem;
  width: 0.75rem;
  background-color: hsl(var(--colour-featureHSL));
  border-radius: 50%;
  font-size: 0.5rem;
  font-weight: 400;
  color: white;
}

.p-datatable-tbody tr {
  border-bottom: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.85 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  line-height: 1.1;
}
.p-datatable-tbody tr:hover {
  background-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.95 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}
.p-datatable-tbody tr.p-highlight {
  background-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.95 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  color: hsl(var(--colour-featureHSL));
}
.p-datatable-tbody td {
  padding: 16px 16px;
}
.p-datatable-tbody td span {
  display: flex;
  align-items: center;
}

.p-filter-column input {
  width: min(16ch, 100%);
}

.p-filter-column.customDynamicFilter input {
  width: min(24ch, 100%);
}

.p-filter-column.timestampFilter :is(span, .p-calendar) {
  display: block;
}
.p-filter-column.timestampFilter :is(span, .p-calendar) :is(input, .p-inputtext) {
  width: min(36ch, 100%);
  cursor: pointer;
}

.p-paginator {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 16px;
  border-top: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.33 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

.p-paginator-pages {
  display: flex;
  gap: 8px;
  margin: 0 16px;
}

.p-paginator-page.p-highlight {
  color: hsl(var(--colour-featureHSL));
}

.p-paginator-current {
  margin-left: auto;
  line-height: 1.4;
}

.hideExpander .p-row-toggler {
  display: none;
}

.p-row-expanded td {
  padding-left: 0;
  padding-bottom: 0;
}

.new {
  color: #00956d;
}

.noName {
  color: #ffbb00;
}

.p-datepicker {
  max-width: 600px;
  margin-top: 2px;
  padding: 16px;
  background: #ffffff;
  color: hsl(var(--colour-fontHSL));
  border-radius: 4px;
  font-size: 0.75rem;
}
.p-datepicker.columnFilter {
  font-size: 0.75rem;
}

.p-datepicker:not(.p-datepicker-inline) {
  background: #ffffff;
  border: 0 none;
  box-shadow: var(--box-shadow);
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background: #ffffff;
}

.p-datepicker .p-datepicker-header {
  padding: 8px;
  background: #ffffff;
  border-bottom: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.75 + var(--colour-font-l)));
  font-weight: 700;
}
.p-datepicker .p-datepicker-header :is(.p-datepicker-prev, .p-datepicker-next) {
  width: 2rem;
  height: 2rem;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 350ms, color 350ms;
}
.p-datepicker .p-datepicker-header :is(.p-datepicker-prev, .p-datepicker-next):enabled:hover {
  border-color: transparent;
}
.p-datepicker .p-datepicker-header .p-datepicker-title {
  line-height: 2rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  margin-right: 8px;
}

.p-datepicker .p-datepicker-header .p-datepicker .p-datepicker-header .p-datepicker-title select {
  transition: background-color 350ms, color 350ms, border-color 350ms;
}

.p-datepicker table {
  margin: 0.5rem 0;
}
.p-datepicker table th,
.p-datepicker table td {
  padding: 0.5rem;
}
.p-datepicker table th > span,
.p-datepicker table td > span {
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
  transition: background-color 350ms ease;
}
.p-datepicker table td > span.p-highlight {
  background: hsl(var(--colour-feature-h), var(--colour-feature-s), calc((100% - var(--colour-feature-l)) * 0.75 + var(--colour-feature-l)));
}
.p-datepicker table td > span.p-disabled {
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.66 + var(--colour-font-l)));
}
.p-datepicker table td.p-datepicker-today > span {
  border: 1px solid hsl(var(--colour-fontHSL));
}

.p-datepicker .p-datepicker-buttonbar {
  padding: 1rem 0;
  border-top: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.75 + var(--colour-font-l)));
}

.p-datepicker .p-datepicker-buttonbar .p-button {
  width: auto;
}

.p-dialog-mask {
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
}

.p-dialog {
  padding: 32px;
  width: min(90%, 750px);
  background-color: white;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  color: hsl(var(--colour-fontHSL));
}

.p-dialog-header {
  font-weight: 700;
}

.p-dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.p-dialog-content {
  padding: 32px 0px;
}

.loadingDialog {
  color: black;
}

.p-dropdown,
.p-multiselect {
  display: inline-flex;
  align-items: center;
  width: auto;
  padding: 8px 16px;
  background-color: hsl(var(--body-background-h), var(--body-background-s), calc((100% - var(--body-background-l)) * 0.15 * var(--emphasis-multiplyer) + var(--body-background-l)));
  border: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  border-radius: 4px;
  color: hsl(var(--colour-fontHSL));
}
.p-dropdown.error,
.p-multiselect.error {
  border-color: var(--colourRed);
  border-right-width: 16px;
}

.p-dropdown-clear-icon,
.p-multiselect-clear-icon {
  position: relative;
  margin: 0;
  top: auto;
  padding-right: 8px;
  order: -1;
}

.p-dropdown-label,
.p-multiselect-label {
  color: hsl(var(--colour-fontHSL));
  font-weight: 400;
}

.p-dropdown-trigger,
.p-multiselect-trigger {
  padding-left: 16px;
  color: hsl(var(--colour-fontHSL));
}

.p-dropdown-panel,
.p-multiselect-panel,
.p-autocomplete-panel {
  margin-top: 2px;
  background-color: white;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
}

.p-dropdown-item,
.p-multiselect-item,
.p-autocomplete-item {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  transition: background-color 350ms ease;
  color: hsl(var(--colour-fontHSL));
}
.p-dropdown-item:hover:not(.p-disabled),
.p-multiselect-item:hover:not(.p-disabled),
.p-autocomplete-item:hover:not(.p-disabled) {
  background-color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.9 + var(--colour-font-l)));
}

.p-multiselect-header {
  padding: 8px 16px;
  border-bottom: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.75 + var(--colour-font-l)));
}

.p-dropdown.p-disabled {
  pointer-events: auto;
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}
.p-dropdown.p-disabled:is(:hover, :focus) {
  border-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}
.p-dropdown.p-disabled .p-dropdown-label,
.p-dropdown.p-disabled .p-dropdown-trigger {
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

.p-dropdown-filter-container,
.p-multiselect-filter-container {
  display: flex;
  margin: 8px 16px;
}
.p-dropdown-filter-container .p-dropdown-filter-icon,
.p-dropdown-filter-container .p-multiselect-filter-icon,
.p-multiselect-filter-container .p-dropdown-filter-icon,
.p-multiselect-filter-container .p-multiselect-filter-icon {
  right: 1rem;
  color: hsl(var(--colour-fontHSL));
}

.p-multiselect-filter-container {
  margin-left: 0;
}

.feature:is(.p-dropdown, .p-multiselect) {
  border-color: hsl(var(--colour-featureHSL));
  background-color: hsl(var(--colour-featureHSL));
  box-shadow: var(--box-shadow);
}
.feature:is(.p-dropdown, .p-multiselect) .p-dropdown-label,
.feature:is(.p-dropdown, .p-multiselect) .p-dropdown-trigger {
  color: #fff;
}

.p-inputswitch {
  width: 3rem;
  height: 1.75rem;
}
.p-inputswitch .p-inputswitch-slider {
  background: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 + var(--colour-font-l)));
  transition: background-color 0.2s, color 0.2s;
  border-radius: 30px;
}
.p-inputswitch .p-inputswitch-slider:before {
  background: #ffffff;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: hsl(var(--colour-featureHSL));
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  background: #ffffff;
  transform: translateX(1.25rem);
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.33 + var(--colour-font-l)));
}

.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
  background-color: hsl(var(--colour-feature-h), var(--colour-feature-s), calc((100% - var(--colour-feature-l)) * 0.15 + var(--colour-feature-l)));
}

.p-listbox-list-wrapper {
  overflow: auto;
}

.p-listbox-item {
  padding-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;
  transition: border 350ms ease, padding 350ms ease;
}
.p-listbox-item:hover {
  background-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.95 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}
.p-listbox-item.p-highlight {
  border-left: 6px solid hsl(var(--colour-featureHSL));
}
.p-listbox-item.p-highlight p:first-of-type {
  color: hsl(var(--colour-featureHSL));
}

.p-listbox-item + .p-listbox-item {
  border-top: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  padding-top: 16px;
}

.p-listbox-filter-container {
  display: flex;
  margin-bottom: 16px;
}
.p-listbox-filter-container .p-listbox-filter-icon {
  right: 1rem;
  color: hsl(var(--colour-fontHSL));
}

.p-menu-overlay {
  margin-top: 4px;
  background-color: hsla(var(--body-background-h), var(--body-background-s), var(--body-background-l), 0.8);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  border: 3px solid hsla(var(--body-background-h), var(--body-background-s), var(--body-background-l), 0.4);
  background-clip: padding-box;
}

.p-menu {
  min-width: 12rem;
  padding: 16px 0px;
}

.p-menuitem {
  list-style: none;
}
.p-menuitem + .p-submenu-header {
  padding-top: 16px;
}

.p-submenu-header {
  list-style: none;
  padding: 8px 16px;
  font-weight: 700;
  color: hsl(var(--colour-fontHSL));
}

.p-menuitem-link {
  padding: 8px 16px;
  background-color: transparent;
  color: hsl(var(--colour-fontHSL));
  transition: color 350ms ease;
}
.p-menuitem-link:not(.p-disabled):hover {
  color: hsl(var(--colour-featureHSL));
}

.p-menuitem-icon {
  margin-right: 16px;
}

.flat .p-submenu-header {
  display: none;
}

.p-steps .p-steps-item .p-menuitem-link {
  background: #ffffff;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-number {
  z-index: 1;
  height: 2rem;
  width: 2rem;
  background: #ffffff;
  border: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 + var(--colour-font-l)));
  border-radius: 50%;
  line-height: 2rem;
  font-size: 1rem;
  color: hsl(var(--colour-fontHSL));
}

.p-steps .p-steps-item .p-menuitem-link .p-steps-title {
  margin-top: 0.5rem;
  color: hsl(var(--colour-fontHSL));
}

.p-steps .p-steps-item .p-menuitem-link:not(.p-disabled):focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: hsl(var(--colour-featureHSL));
  border-color: hsl(var(--colour-featureHSL));
  color: #fff;
}

.p-steps .p-steps-item.p-highlight .p-steps-title {
  font-weight: 600;
  color: hsl(var(--colour-fontHSL));
}

.p-steps .p-steps-item:before {
  content: " ";
  border-top: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 + var(--colour-font-l)));
  width: 100%;
  top: 50%;
  left: 0;
  display: block;
  position: absolute;
  margin-top: -1rem;
}

.p-toast-icon-close {
  position: absolute;
  top: -0.45rem;
  right: -0.45rem;
  height: 1.5rem;
  width: 1.5rem;
  background-color: white;
  border: 2px solid;
  border-color: inherit;
  border-radius: 50%;
  color: inherit;
}
.p-toast-icon-close:hover {
  background-color: white;
}

.p-toast-message {
  position: relative;
  padding: 8px 16px;
  border: 2px solid;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
}
.p-toast-message + .p-toast-message {
  margin-top: 32px;
}
.p-toast-message-content {
  display: flex;
  align-items: center;
}
.p-toast-message-success {
  background-color: #bfe5db;
  border-color: #00956d;
  color: #00956d;
}
.p-toast-message-success .p-toast-icon-close:hover {
  border-color: #00956d;
  color: #00956d;
}
.p-toast-message-error {
  background-color: #f0cdd0;
  border-color: #c13744;
  color: #c13744;
}
.p-toast-message-error .p-toast-icon-close:hover {
  border-color: #c13744;
  color: #c13744;
}
.p-toast-message-info {
  background-color: #bfdef2;
  border-color: #007acc;
  color: #007acc;
}
.p-toast-message-info .p-toast-icon-close:hover {
  border-color: #007acc;
  color: #007acc;
}
.p-toast-message-warn {
  background-color: #ffeebf;
  border-color: #ffbb00;
  color: #ffbb00;
}
.p-toast-message-warn .p-toast-icon-close:hover {
  border-color: #ffbb00;
  color: #ffbb00;
}

.p-toast-message-icon {
  margin-right: 16px;
  font-size: clamp(1.25rem, 2.5vmin, 1.75rem);
}

.p-toast-summary {
  font-size: clamp(1rem, 1.5vmin, 2.5rem);
  font-weight: 700;
}

.withPTooltip {
  cursor: pointer;
}

.p-tooltip {
  z-index: 10;
  max-width: 45ch;
  padding: 8px 16px;
  background: hsl(var(--colour-fontHSL));
  border-radius: 4px;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.9 + var(--colour-font-l)));
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  transform: translateY(-0.5rem);
}
.p-tooltip:is(.p-tooltip-left, .p-tooltip-right) {
  padding: 8px 16px;
}

.p-selectbutton .p-button.p-highlight {
  background: #2196f3;
  border-color: #2196f3;
  color: #ffffff;
}

.p-selectbutton .p-button.p-highlight:hover {
  background: #0d89ec;
  border-color: #0d89ec;
  color: #ffffff;
}

.p-skeleton {
  background-color: hsl(var(--colour-font-lightest-hsl));
}
.p-skeleton:not(.p-skeleton-circle) {
  border-radius: var(--borderRadius);
}

.p-skeleton:after {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
}

.paginatorSkeleton {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 16px 16px;
  border-top: 1px solid hsl(var(--colour-font-hsl));
}

.p-slider {
  position: relative;
}

.p-slider .p-slider-handle {
  position: absolute;
  cursor: grab;
  touch-action: none;
  display: block;
  z-index: 1;
}

.p-slider .p-slider-handle.p-slider-handle-active {
  z-index: 2;
}

.p-slider-range {
  position: absolute;
  display: block;
}

.p-slider-horizontal .p-slider-range {
  top: 0;
  left: 0;
  height: 100%;
}

.p-slider-horizontal .p-slider-handle {
  top: 50%;
}

.p-slider-vertical {
  height: 100px;
}

.p-slider-vertical .p-slider-handle {
  left: 50%;
}

.p-slider-vertical .p-slider-range {
  bottom: 0;
  left: 0;
  width: 100%;
}

.p-slider {
  background: #dee2e6;
  border: 0 none;
  border-radius: 3px;
}

.p-slider.p-slider-horizontal {
  height: 0.286rem;
}

.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -0.5715rem;
  margin-left: -0.5715rem;
}

.p-slider.p-slider-vertical {
  width: 0.286rem;
}

.p-slider.p-slider-vertical .p-slider-handle {
  margin-left: -0.5715rem;
  margin-bottom: -0.5715rem;
}

.p-slider .p-slider-handle {
  height: 1.143rem;
  width: 1.143rem;
  background: #ffffff;
  border: 2px solid hsl(var(--colour-featureHSL));
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

.p-slider .p-slider-handle:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-slider .p-slider-range {
  background: hsl(var(--colour-featureHSL));
}

.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: hsl(var(--colour-featureHSL));
  border-color: hsl(var(--colour-featureHSL));
}

.p-tag {
  background: #2196f3;
  color: #ffffff;
  font-size: 0.7rem;
  font-weight: 700;
  padding: 0.25rem 0.4rem;
  border-radius: 3px;
}
.p-tag.private {
  background-color: #007acc;
}
.p-tag.public {
  background-color: #00956d;
}
.p-tag .p-tag-icon {
  margin-right: 8px;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1rem;
  border: 1px solid #dee2e6;
  color: #627893;
  background: #f8f9fa;
  font-weight: 600;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

.p-accordion .p-accordion-header .p-accordion-header-link .p-accordion-toggle-icon {
  margin-right: 0.5rem;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: #e9ecef;
  border-color: #dee2e6;
  color: #627893;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: #f8f9fa;
  border-color: #dee2e6;
  color: #627893;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-color: #dee2e6;
  background: #e9ecef;
  color: #627893;
}

.p-accordion .p-accordion-content {
  padding: 1rem;
  border: 1px solid #dee2e6;
  background: #ffffff;
  color: #627893;
  border-top: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion .p-accordion-tab {
  margin-bottom: 0;
}

.p-accordion .p-accordion-tab .p-accordion-header .p-accordion-header-link {
  border-radius: 0;
}

.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link,
.p-accordion .p-accordion-tab:not(:first-child) .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  border-top: 0 none;
}

.p-accordion .p-accordion-tab:first-child .p-accordion-header .p-accordion-header-link {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-header-link {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.p-accordion .p-accordion-tab:last-child .p-accordion-header:not(.p-highlight) .p-accordion-content {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.infoWindowContent {
  min-width: 200px;
  padding: 8px;
}
.infoWindowContent h3 {
  margin-bottom: 8px;
}
.infoWindowContent p + p {
  margin-top: 4px;
}

/*
	General button styles
	include .bttn class for making links look like buttons
	include .p-button to override primefaces styles
*/
.bttn,
.p-button,
button,
input[type=submit] {
  cursor: pointer;
  position: relative;
  min-width: max-content;
  padding: 8px 16px;
  background: transparent;
  border: 1px solid hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.25 + var(--colour-font-l)));
  border-radius: 4px;
  color: hsl(var(--colour-fontHSL));
  overflow: hidden;
  transition: color 350ms ease, border-color 350ms ease, color 350ms ease;
}
.bttn:hover,
.p-button:hover,
button:hover,
input[type=submit]:hover {
  border-color: hsl(var(--colour-feature-h), var(--colour-feature-s), var(--colour-feature-l));
  color: hsl(var(--colour-feature-h), var(--colour-feature-s), var(--colour-feature-l));
}
.bttn:is(.p-disabled, :disabled),
.p-button:is(.p-disabled, :disabled),
button:is(.p-disabled, :disabled),
input[type=submit]:is(.p-disabled, :disabled) {
  cursor: not-allowed !important;
  border-color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.66 + var(--colour-font-l)));
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 + var(--colour-font-l)));
}
.bttn:not(.p-column-filter-menu-button),
.p-button:not(.p-column-filter-menu-button),
button:not(.p-column-filter-menu-button),
input[type=submit]:not(.p-column-filter-menu-button) {
  position: relative;
}
.bttn:not(.p-column-filter-menu-button)::before,
.p-button:not(.p-column-filter-menu-button)::before,
button:not(.p-column-filter-menu-button)::before,
input[type=submit]:not(.p-column-filter-menu-button)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: hsl(var(--colour-featureHSL));
  border-radius: 0 0 50% 50%;
  transform: scale(0);
  transform-origin: top;
  transition: transform 350ms ease;
}
.bttn:active::before,
.p-button:active::before,
button:active::before,
input[type=submit]:active::before {
  opacity: 15%;
  transform: scale(1.5);
}
.bttn.feature,
.p-button.feature,
button.feature,
input[type=submit].feature {
  border-color: hsl(var(--colour-featureHSL));
  background-color: hsl(var(--colour-featureHSL));
  color: #fff;
  box-shadow: var(--box-shadow);
  position: relative;
}
.bttn.feature:is(:hover),
.p-button.feature:is(:hover),
button.feature:is(:hover),
input[type=submit].feature:is(:hover) {
  border-color: hsl(var(--colour-feature-h), var(--colour-feature-s), calc((100% - var(--colour-feature-l)) * 0.15 + var(--colour-feature-l)));
  background-color: hsl(var(--colour-feature-h), var(--colour-feature-s), calc((100% - var(--colour-feature-l)) * 0.15 + var(--colour-feature-l)));
}
.bttn.feature::before,
.p-button.feature::before,
button.feature::before,
input[type=submit].feature::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
}
.bttn.feature:is(.p-disabled, :disabled),
.p-button.feature:is(.p-disabled, :disabled),
button.feature:is(.p-disabled, :disabled),
input[type=submit].feature:is(.p-disabled, :disabled) {
  cursor: not-allowed !important;
  border-color: hsl(var(--colour-feature-h), calc(var(--colour-feature-s) / 2), var(--colour-feature-l));
  background-color: hsl(var(--colour-feature-h), calc(var(--colour-feature-s) / 2), var(--colour-feature-l));
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-feature-l)) * 0.75 + var(--colour-feature-l)));
}
.bttn.error:not(.withBackground),
.p-button.error:not(.withBackground),
button.error:not(.withBackground),
input[type=submit].error:not(.withBackground) {
  border-color: #c13744;
  background-color: #c13744;
  color: #fff;
  box-shadow: var(--box-shadow);
}
.bttn.error:not(.withBackground):disabled,
.p-button.error:not(.withBackground):disabled,
button.error:not(.withBackground):disabled,
input[type=submit].error:not(.withBackground):disabled {
  border-color: #8d5258;
  background-color: #8d5258;
}
.bttn.success:not(.withBackground),
.p-button.success:not(.withBackground),
button.success:not(.withBackground),
input[type=submit].success:not(.withBackground) {
  border-color: #00956d;
  background-color: #00956d;
  color: #fff;
  box-shadow: var(--box-shadow);
}
.bttn.round,
.p-button.round,
button.round,
input[type=submit].round {
  height: 2rem;
  width: 2rem;
  padding: 0;
  border-radius: 50%;
}
.bttn.round.large,
.p-button.round.large,
button.round.large,
input[type=submit].round.large {
  height: 3rem;
  width: 3rem;
  font-size: 1rem;
}
.bttn.noBorder,
.p-button.noBorder,
button.noBorder,
input[type=submit].noBorder {
  border: none;
}
.bttn.withBackground,
.p-button.withBackground,
button.withBackground,
input[type=submit].withBackground {
  background-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  transition: color 350ms ease, background-color 350ms ease;
  color: #fff;
}
.bttn.withBackground:hover,
.p-button.withBackground:hover,
button.withBackground:hover,
input[type=submit].withBackground:hover {
  background-color: hsl(var(--colour-featureHSL));
  color: #fff;
}
.bttn.withBackground.error:hover,
.p-button.withBackground.error:hover,
button.withBackground.error:hover,
input[type=submit].withBackground.error:hover {
  background-color: #c13744;
}

.p-button-icon-only.p-button-rounded {
  box-sizing: border-box;
  padding: 0.5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.p-button-secondary {
  border: none;
  padding: 0;
}

.p-row-toggler:hover {
  background: none;
}

/*
	Overrides for Primefaces split button
*/
.p-splitbutton .p-splitbutton-menubutton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

button > i,
.p-button-icon-left {
  margin-right: 12px;
}

.p-button-icon-right {
  margin-left: 12px;
}

.appHeader :is(.bttn, .p-button, button, input[type=submit]):not(.feature) {
  cursor: pointer;
  border-width: 2px;
  border-color: hsl(var(--colour-fontHSL));
  color: hsl(var(--colour-fontHSL));
}
.appHeader :is(.bttn, .p-button, button, input[type=submit]):not(.feature):hover {
  background-color: transparent;
  border-color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.33 + var(--colour-font-l)));
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.25 + var(--colour-font-l)));
}

.pageHeader :is(.bttn, .p-button, button, input[type=submit]):not(.feature) {
  cursor: pointer;
  border-color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.75 + var(--colour-font-l)));
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.66 + var(--colour-font-l)));
  position: relative;
}
.pageHeader :is(.bttn, .p-button, button, input[type=submit]):not(.feature)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
}
.pageHeader .bttn:not(.feature):hover,
.pageHeader .p-button:not(.feature):hover,
.pageHeader button:not(.feature):hover {
  border-color: hsl(var(--colour-feature-h), var(--colour-feature-s), calc((100% - var(--colour-feature-l)) * 1 + var(--colour-feature-l)));
  color: hsl(var(--colour-feature-h), var(--colour-feature-s), calc((100% - var(--colour-feature-l)) * 1 + var(--colour-feature-l)));
}

.columnFilter button {
  font-size: 0.75rem;
}

.card {
  padding: 64px;
  background-color: hsla(var(--body-background-h), var(--body-background-s), var(--body-background-l), 0.8);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  border: 3px solid hsla(var(--body-background-h), var(--body-background-s), var(--body-background-l), 0.4);
  background-clip: padding-box;
}
.card.noPadding {
  padding: 0;
  overflow: hidden;
}

.expandable {
  cursor: pointer;
  box-sizing: border-box;
  transition: width 3s ease;
}

.expand-false {
  width: 99%;
}

.expand-true {
  width: 100%;
  grid-column: 1/last-line;
}

@media screen and (max-width: 960px) {
  .card {
    padding: 32px;
  }
}
.cardSummary {
  padding: 48px;
}
.cardSummary h2 {
  margin-bottom: 16px;
}

.chartContainer {
  min-height: 600px;
}

.floorPlanContainer {
  height: 50vh;
  width: 100%;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  border: 3px solid hsla(var(--body-background-h), var(--body-background-s), var(--body-background-l), 0.4);
  background-clip: padding-box;
  overflow: hidden;
}

.floorPlanMapWrapper {
  position: relative;
  height: 60vh;
  width: 100%;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  backdrop-filter: blur(10px);
  border: 3px solid hsla(var(--body-background-h), var(--body-background-s), var(--body-background-l), 0.4);
  background-color: white;
  background-clip: padding-box;
  overflow: hidden;
}
.floorPlanMapWrapper .hoverDevice {
  position: absolute;
  top: 32px;
  right: 16px;
}
.floorPlanMapWrapper .hoverDevice span {
  padding: 16px;
  background: hsl(var(--colour-featureHSL));
  border-radius: 4px;
  color: hsl(var(--body-backgroundHSL));
}
.floorPlanMapWrapper .typeFilter {
  position: absolute;
  bottom: 32px;
  left: 32px;
  display: flex;
  flex-direction: column;
}
.floorPlanMapWrapper .typeFilter span {
  cursor: pointer;
}

#floorPlanMap {
  height: 100%;
  width: 100%;
}

.maptalks-zoom {
  padding: 16px;
  background: hsla(var(--body-background-h) var(--body-background-s) var(--body-background-l)/0.8);
  border-radius: 29px;
}

.maptalks-zoom-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.maptalks-zoom-slider-box {
  position: relative;
  display: flex;
  justify-content: center;
  width: 27px;
}
.maptalks-zoom-slider-box .maptalks-zoom-slider-dot {
  position: absolute;
  width: 15px;
  aspect-ratio: 1/1;
  background: hsl(var(--colour-featureHSL));
  border-radius: 50%;
}
.maptalks-zoom-slider-box .maptalks-zoom-slider-ruler {
  position: relative;
  width: 3px;
  height: 112px;
  background: hsl(var(--colour-appHSL));
  border-radius: 1px;
  margin: 4px auto;
}

.maptalks-zoom-slider a.maptalks-zoom-zoomin,
.maptalks-zoom-slider a.maptalks-zoom-zoomout {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  width: 100%;
  aspect-ratio: 1/1;
  border: 0;
  background: hsl(var(--colour-featureHSL));
  border-radius: 50%;
  color: #fff;
  line-height: 1;
}

.withTooltip {
  position: relative;
  cursor: pointer;
}
.withTooltip::after {
  z-index: 2;
  content: attr(aria-label);
  position: absolute;
  width: max-content;
  top: 110%;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 8px 16px;
  background: hsl(var(--colour-fontHSL));
  border-radius: 4px;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.9 + var(--colour-font-l)));
  font-family: "Poppins", sans-serif;
  font-size: 0.75rem;
  opacity: 0;
  transform: scale(0);
  transform-origin: top;
  transition: transform 0ms ease-in-out 100ms, opacity 100ms linear;
}
.withTooltip:hover::after {
  opacity: 1;
  transform: scale(1);
  transition: transform 100ms ease-in-out, opacity 100ms linear;
}

.formField {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 0.75rem;
  font-weight: 700;
}

input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext) {
  box-sizing: border-box;
  width: 100%;
  padding: 8px 16px;
  border: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  border-radius: 4px;
  background-color: hsl(var(--body-background-h), var(--body-background-s), calc((100% - var(--body-background-l)) * 0.15 * var(--emphasis-multiplyer) + var(--body-background-l)));
  color: hsl(var(--colour-fontHSL));
  transition: colour 350ms ease, border 350ms ease;
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext).error {
  border-color: #c13744;
  border-right-width: 16px;
}
input:is([type=text], [type=password], [type=email], [type=number], .p-inputtext)::placeholder {
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

input:is(:hover, :focus),
textarea:is(:hover, :focus) {
  border-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.33 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

input:is(:focus),
textarea:is(:focus) {
  background-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.95 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

span:not(.p-calendar) input:is(:read-only),
input:is(:disabled),
textarea:is(:disabled, :read-only) {
  cursor: not-allowed;
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}
span:not(.p-calendar) input:is(:read-only):is(:hover, :focus),
input:is(:disabled):is(:hover, :focus),
textarea:is(:disabled, :read-only):is(:hover, :focus) {
  border-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

.errors {
  padding: 0;
}
.errors li {
  list-style: none;
  font-size: 0.75rem;
}

textarea:is([type=text], [type=password], [type=email], [type=number], .p-inputtextarea) {
  padding: 8px 16px;
  border: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  border-radius: 4px;
  background-color: hsl(var(--body-background-h), var(--body-background-s), calc((100% - var(--body-background-l)) * 0.15 * var(--emphasis-multiplyer) + var(--body-background-l)));
  color: hsl(var(--colour-fontHSL));
  transition: colour 350ms ease, border 350ms ease;
}
textarea:is([type=text], [type=password], [type=email], [type=number], .p-inputtextarea).error {
  border-color: #c13744;
  border-right-width: 16px;
}
textarea:is([type=text], [type=password], [type=email], [type=number], .p-inputtextarea)::placeholder {
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

.p-password.error input {
  border-color: #c13744;
  border-right-width: 16px;
}

.p-input-icon-right i {
  right: 16px;
}
.p-input-icon-right.error i {
  right: 32px;
}
.p-input-icon-right.p-password i {
  cursor: pointer;
}

.loadingDialog {
  width: auto;
  background-color: transparent;
  box-shadow: none;
}
.loadingDialog .p-dialog-content {
  text-align: center;
}
.loadingDialog .message {
  margin-top: 32px;
  font-size: clamp(1.25rem, 2.5vmin, 1.75rem);
  font-weight: bold;
  text-transform: uppercase;
  color: var(--colour-fontHSL);
}
.loadingDialog .loadingDot {
  animation-name: loadingDot;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.loadingDialog .loadingDot:nth-child(1) {
  animation-delay: 0.5s;
}
.loadingDialog .loadingDot:nth-child(2) {
  animation-delay: 1s;
}

.p-progress-spinner-circle {
  animation-duration: 1.5s, 3s;
}

@keyframes p-progress-spinner-color {
  100%, 0% {
    stroke: hsl(var(--colour-fontHSL));
  }
  50% {
    stroke: hsl(var(--colour-featureHSL));
  }
}
@keyframes loadingDot {
  100%, 0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}
.logoImg {
  position: relative;
  height: auto;
  width: 100%;
}

#logo {
  display: block;
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 760px) {
  .logoImg {
    width: 75%;
    margin: 0 auto;
  }
}
.mapSearchWrapper {
  position: absolute;
  z-index: 10;
  top: 8px;
  left: 8px;
}
.mapSearchWrapper input {
  border-radius: 4px 0 0 4px;
}
.mapSearchWrapper button.feature {
  height: calc(1.6rem + 16px);
  border-radius: 0 4px 4px 0;
  box-shadow: none;
}

.summaryItemWrapper .summaryItemLabel {
  text-transform: uppercase;
  font-weight: 700;
}
.summaryItemWrapper .summaryItemContent {
  display: flex;
  align-items: center;
  gap: 16px;
  min-height: clamp(1.25rem, 2.5vmin, 1.75rem);
  width: 100%;
  margin: 0;
  font-size: clamp(1.25rem, 2.5vmin, 1.75rem);
  font-weight: 700;
  line-height: 1;
}
.summaryCard {
  padding: 48px;
}
.summaryCard header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.summaryCard header div:first-of-type {
  transform: translateX(-1.5rem);
}
.summaryCard header div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.appLogin .logoImg {
  margin-bottom: 32px;
}
.appLogin form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 64px;
}
.appLogin button[type=submit] {
  margin-top: 32px;
}

.app:not(.showHUD) .appLogo {
  text-align: center;
}

.divider {
  width: 25%;
  height: 1px;
  background-color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.75 * var(--emphasis-multiplyer) + var(--colour-app-l)));
  margin: 0 auto;
}

.appName {
  margin-right: -0.15em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  line-height: 1;
  font-size: clamp(1.25rem, 2.5vmin, 1.75rem);
}

.appNameSub {
  margin-right: -0.2em;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 + var(--colour-font-l)));
}

.noHud {
  grid-area: appLogin;
  height: 100%;
  width: min(90%, 400px);
  padding-top: 32px;
  display: grid;
  grid-template-areas: "noHudHeader" "noHudMain";
  grid-template-rows: auto auto;
}
.noHud .noHudTitle {
  grid-area: noHudHeader;
}
.noHud .noHudMain {
  grid-area: noHudMain;
  align-items: center;
}
.noHud .noHudMain h2 {
  text-align: center;
}
.noHud button[type=submit] {
  margin-top: 32px;
}

.reportSeriesWrapper {
  display: grid;
  grid-template-columns: auto 1fr auto;
}

h1,
h2,
h3 {
  margin: 0;
  line-height: 1;
}

h1,
.h1,
.xLarge {
  font-size: clamp(2rem, 3.75vmin, 5rem);
  font-weight: 700;
}

h2,
.h2 {
  font-size: clamp(1.25rem, 2.5vmin, 1.75rem);
  font-weight: 700;
}

h3,
.h3 {
  font-size: clamp(1rem, 1.5vmin, 2.5rem);
  font-weight: 700;
}

.large {
  font-size: clamp(1.25rem, 2.5vmin, 1.75rem);
}

.medium {
  font-size: clamp(1rem, 1.5vmin, 2.5rem);
}

.small {
  font-size: 0.75rem;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.fontStd {
  color: hsl(var(--colour-fontHSL));
}

p {
  margin: 0;
  line-height: 1.4;
}
p + p {
  margin-top: 16px;
}

a {
  text-decoration: none;
  color: hsl(var(--colour-featureHSL));
  transition: color 350ms ease;
}
a:hover:not(.p-disabled) {
  color: hsl(var(--colour-feature-h), var(--colour-feature-s), calc(var(--colour-feature-l) * 1.33));
}

input,
textarea,
button {
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}

.error,
.errorText {
  color: #c13744;
}

.success,
.successText {
  color: #00956d;
}

.warn,
.warnText {
  color: #ffbb00;
}

.info,
.infoText {
  color: #007acc;
}

.thin {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.withIcon {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.light {
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.25 + var(--colour-font-l)));
}

.failList {
  padding-left: 0;
}
.failList :is(.failItem, .successItem) {
  list-style: none;
}
.failList :is(.failItem, .successItem)::before {
  font-family: "primeicons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  font-size: 0.75rem;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 8px;
}
.failList .failItem::before {
  content: "\e90c";
  color: #c13744;
}
.failList .successItem::before {
  content: "\e90a";
  color: #00956d;
}

:focus {
  outline: none;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
}

[class$=Content] {
  box-sizing: border-box;
  width: min(90%, 1200px);
  margin: 0 auto;
}

.app {
  display: grid;
  grid-template-areas: "appLogin" "appFooter";
  grid-template-columns: 1fr;
  grid-template-rows: 1fr auto;
  justify-items: center;
  align-items: center;
  min-height: 100vh;
  background-color: hsl(var(--body-background-h), var(--body-background-s), var(--body-background-l));
  color: hsl(var(--colour-fontHSL));
}

.app.showHUD {
  display: grid;
  grid-template-areas: "appHeader" "pageHeader" "main" "appFooter";
  grid-template-rows: auto auto 1fr auto;
  justify-items: stretch;
  align-items: normal;
  min-height: 100vh;
  color: hsl(var(--colour-fontHSL));
}

.appLogin {
  grid-area: appLogin;
  width: min(90%, 400px);
}

.appHeader {
  grid-area: appHeader;
  isolation: isolate;
  padding-top: 16px;
  position: relative;
}
.appHeader::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 25rem;
  width: 100%;
  z-index: -1;
  background-image: var(--page-header-background);
  background-size: cover;
}
.appHeaderContent {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
}
.appHeaderContent > div {
  display: flex;
  align-items: center;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 + var(--colour-font-l)));
  font-size: 0.75rem;
}
.appHeaderContent > div img {
  height: 2rem;
  margin-right: 8px;
}

.appNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.pageHeader {
  grid-area: pageHeader;
  padding-top: 96px;
  color: white;
  z-index: 1;
}
.pageHeaderContent {
  display: grid;
  grid-template-areas: "pageTitle pageTools";
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
}
.pageHeaderContent .pageTitleWrapper {
  grid-area: pageTitle;
  display: flex;
  align-items: center;
  gap: 16px;
}
.pageHeaderContent .pageTitleWrapper .pageTitle {
  position: relative;
  width: 100%;
  text-transform: uppercase;
}
.pageHeaderContent .pageTitleWrapper .pageSubtitle {
  position: absolute;
  left: 0;
  top: clamp(-1rem - 4px, -1.5vmin - 4px, -2.5rem - 4px);
  text-transform: uppercase;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 * var(--emphasis-multiplyer) + var(--colour-font-l)));
}
.pageHeaderContent .pageTools {
  grid-area: pageTools;
  display: flex;
  gap: 24px;
}

main {
  grid-area: main;
  position: relative;
  overflow: hidden;
}

.mainContent {
  position: relative;
  display: grid;
  grid-auto-flow: row;
  row-gap: 64px;
  margin: 0 auto;
  padding: 64px 0;
  transition: transform 350ms ease 100ms, opacity 350ms ease, filter 350ms ease;
}

.appFooter {
  grid-area: appFooter;
  width: 100%;
  padding: 16px 0;
  color: hsl(var(--colour-font-h), var(--colour-font-s), calc((100% - var(--colour-font-l)) * 0.5 * var(--emphasis-multiplyer) + var(--colour-font-l)));
  font-size: 0.75rem;
}
.appFooterContent {
  text-align: center;
}

.map {
  position: relative;
  height: min(80vh, 750px);
}

ul {
  margin: 0;
  padding: 0 0 0 1.2rem;
}
ul li {
  list-style: circle;
  margin: 0;
}

.noMargin {
  margin: 0;
}

.marginPageHeader {
  margin-top: 12rem;
}

.block {
  display: block;
}

.inlineBlock {
  display: inline-block;
}

.flexVertCentre {
  display: flex;
  align-items: center;
  gap: 16px;
}

.infoWrapper {
  padding-left: 16px;
  border-left: 3px solid #007acc;
}

.asBackground {
  width: max-content;
  padding: 8px 8px;
  border-radius: 4px;
  color: white;
}
.asBackground.success {
  background-color: #00956d;
}
.asBackground.error {
  background-color: #c13744;
}
.asBackground.info {
  background-color: #007acc;
}
.asBackground.warn {
  background-color: #ffbb00;
}

.p-disabled {
  cursor: not-allowed !important;
  pointer-events: auto;
}
.p-disabled * {
  color: hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

.highlightItem {
  align-items: center;
  width: max-content;
}

@media screen and (max-width: 960px) {
  .pageHeaderContent {
    grid-template-areas: "pageTitle" "pageTools";
    grid-template-columns: 1fr;
  }
  .pageHeaderContent .pageTools {
    gap: 0;
    justify-content: space-between;
  }
}
.marginXSmall {
  margin: 8px;
}

.marginTopXSmall {
  margin-top: 8px;
}

.marginBottomXSmall {
  margin-bottom: 8px;
}

.marginLeftXSmall {
  margin-left: 8px;
}

.marginRightXSmall {
  margin-right: 8px;
}

.marginSmall {
  margin: 16px;
}

.marginTopSmall {
  margin-top: 16px;
}

.marginBottomSmall {
  margin-bottom: 16px;
}

.marginLeftSmall {
  margin-left: 16px;
}

.marginRightSmall {
  margin-right: 16px;
}

.marginMedium {
  margin: 32px;
}

.marginTopMedium {
  margin-top: 32px;
}

.marginBottomMedium {
  margin-bottom: 32px;
}

.marginLeftMedium {
  margin-left: 32px;
}

.marginRightMedium {
  margin-right: 32px;
}

.marginLarge {
  margin: 64px;
}

.marginTopLarge {
  margin-top: 64px;
}

.marginBottomLarge {
  margin-bottom: 64px;
}

.marginLeftLarge {
  margin-left: 64px;
}

.marginRightLarge {
  margin-right: 64px;
}

.marginXLarge {
  margin: 128px;
}

.marginTopXLarge {
  margin-top: 128px;
}

.marginBottomXLarge {
  margin-bottom: 128px;
}

.marginLeftXLarge {
  margin-left: 128px;
}

.marginRightXLarge {
  margin-right: 128px;
}

.paddingXSmall {
  padding: 8px;
}

.paddingTopXSmall {
  padding-top: 8px;
}

.paddingBottomXSmall {
  padding-bottom: 8px;
}

.paddingLeftXSmall {
  padding-left: 8px;
}

.paddingRightXSmall {
  padding-right: 8px;
}

.paddingSmall {
  padding: 16px;
}

.paddingTopSmall {
  padding-top: 16px;
}

.paddingBottomSmall {
  padding-bottom: 16px;
}

.paddingLeftSmall {
  padding-left: 16px;
}

.paddingRightSmall {
  padding-right: 16px;
}

.paddingMedium {
  padding: 32px;
}

.paddingTopMedium {
  padding-top: 32px;
}

.paddingBottomMedium {
  padding-bottom: 32px;
}

.paddingLeftMedium {
  padding-left: 32px;
}

.paddingRightMedium {
  padding-right: 32px;
}

.paddingLarge {
  padding: 64px;
}

.paddingTopLarge {
  padding-top: 64px;
}

.paddingBottomLarge {
  padding-bottom: 64px;
}

.paddingLeftLarge {
  padding-left: 64px;
}

.paddingRightLarge {
  padding-right: 64px;
}

.paddingXLarge {
  padding: 128px;
}

.paddingTopXLarge {
  padding-top: 128px;
}

.paddingBottomXLarge {
  padding-bottom: 128px;
}

.paddingLeftXLarge {
  padding-left: 128px;
}

.paddingRightXLarge {
  padding-right: 128px;
}

.gapXSmall {
  gap: 8px;
}

.gapRowXSmall {
  row-gap: 8px;
}

.gapColXSmall {
  column-gap: 8px;
}

.gapSmall {
  gap: 16px;
}

.gapRowSmall {
  row-gap: 16px;
}

.gapColSmall {
  column-gap: 16px;
}

.gapMedium {
  gap: 32px;
}

.gapRowMedium {
  row-gap: 32px;
}

.gapColMedium {
  column-gap: 32px;
}

.gapLarge {
  gap: 64px;
}

.gapRowLarge {
  row-gap: 64px;
}

.gapColLarge {
  column-gap: 64px;
}

.gapXLarge {
  gap: 128px;
}

.gapRowXLarge {
  row-gap: 128px;
}

.gapColXLarge {
  column-gap: 128px;
}

.grid {
  display: grid;
}

.columnLabel {
  grid-template-columns: auto 1fr;
}
.columnLabel > :nth-child(odd) {
  font-weight: 700;
}

.column2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr) [last-line];
  align-content: flex-start;
}
.column2.autoColumns {
  grid-template-columns: auto auto;
}

.column2-1 {
  grid-template-columns: 3fr 2fr;
}

.column3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr) [last-line];
  align-content: flex-start;
}

.noGap {
  gap: 0;
}

.noHorizGap {
  column-gap: 0;
}

.noVertGap {
  row-gap: 0;
}

.horizGap {
  column-gap: 64px;
}

.vertGap {
  row-gap: 64px;
}

.listGap {
  row-gap: 4px;
}

.jSpaceBetween {
  justify-content: space-between;
}

.jContentStart {
  justify-content: start;
}

.jContentEnd {
  justify-content: end;
}

.aContentStart {
  align-content: flex-start;
}

.aContentEnd {
  align-content: flex-end;
}

.aItemsStart {
  align-items: start;
}

.aItemsEnd {
  align-items: end;
}

.aItemsCenter {
  align-items: center;
}

.jSelfEnd {
  justify-self: end;
}

.aSelfEnd {
  align-self: end;
}

.double {
  grid-column-end: span 2;
}

.flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flexVert {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 960px) {
  .mainContent > .column2, .mainContent > .column2-1, .mainContent > .column3 {
    grid-template-columns: 1fr;
  }
}
form,
.formFieldsWrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.orWrapper {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}
.orWrapper .or {
  display: flex;
  height: 100%;
  align-items: center;
  position: relative;
}
.orWrapper .or span {
  z-index: 2;
  padding: 8px;
  background-color: white;
}
.orWrapper .or::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  left: 50%;
  border-left: 1px solid hsl(var(--colour-app-h), var(--colour-app-s), calc((100% - var(--colour-app-l)) * 0.66 * var(--emphasis-multiplyer) + var(--colour-app-l)));
}

@font-face {
  font-family: "icomoon";
  src: url("../icons/icomoon.eot?6yemdo");
  src: url("../icons/icomoon.eot?6yemdo#iefix") format("embedded-opentype"), url("../icons/icomoon.ttf?6yemdo") format("truetype"), url("../icons/icomoon.woff?6yemdo") format("woff"), url("../icons/icomoon.svg?6yemdo#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-particles:before {
  content: "\e900";
}

.icon-air:before {
  content: "\e901";
}

.icon-code:before {
  content: "\e902";
}

.icon-diagnostics:before {
  content: "\e903";
}

.icon-driver:before {
  content: "\e904";
}

.icon-energy:before {
  content: "\e905";
}

.icon-fixture:before {
  content: "\e906";
}

.icon-gateway:before {
  content: "\e907";
}

.icon-heater:before {
  content: "\e908";
}

.icon-light:before {
  content: "\e909";
}

.icon-offline:before {
  content: "\e90a";
}

.icon-online:before {
  content: "\e90b";
}

.icon-power:before {
  content: "\e90c";
}

.icon-search:before {
  content: "\e90d";
}

.icon-switch:before {
  content: "\e90e";
}

.icon-water:before {
  content: "\e90f";
}
